import React from 'react';
import { render } from 'react-dom';
import SelectSchool from '../components/SelectSchool';
import SignInFields from '../components/SignInFields';
import StudentProfileSelectSchool from '../components/StudentProfileSelectSchool';
import InputPassword from '../components/InputPassword';
import SearchDocuments from '../components/SearchDocuments';
import DocumentAvailableOfflineToggle from '../components/DocumentAvailableOfflineToggle';
import OfflineDocuments from '../components/OfflineDocuments';
import ApproverDocuments from '../components/ApproverDocuments';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const client = new ApolloClient({
    uri: '/graphql',
    cache: new InMemoryCache(),
})

const renderOnDocumentClassName = (Component, className) => {
    const containers = document.getElementsByClassName(className)
    if (containers === null || containers.length === 0) return
    Array.from(containers).forEach((container) => {
        const props = JSON.parse(container.getAttribute('data-props')) || {}
        render(
            <ApolloProvider client={client}>
                <Component {...props} />
            </ApolloProvider>,
            container
        )
    })
}

document.addEventListener('DOMContentLoaded', () => {
    renderOnDocumentClassName(SelectSchool, 'SelectSchool')
    renderOnDocumentClassName(StudentProfileSelectSchool, 'StudentProfileSelectSchool')
    renderOnDocumentClassName(InputPassword, 'InputPassword')
    renderOnDocumentClassName(SearchDocuments, 'SearchDocuments')
    if ('serviceWorker' in navigator) {
        renderOnDocumentClassName(
            DocumentAvailableOfflineToggle,
            'DocumentAvailableOfflineToggle'
        )
    }
    renderOnDocumentClassName(OfflineDocuments, 'OfflineDocuments')
    renderOnDocumentClassName(ApproverDocuments, 'ApproverDocuments')
    renderOnDocumentClassName(SignInFields, 'SignInFields')
})
