import React, { Fragment, useState } from 'react'

export const DOCUMENT_CACHE_NAME = 'sw-cache-v1';

const OfflineDocuments = () => {
    const userId = Cookies.get('user_id')
    const [documents, setDocuments] = useState(getOfflineDocuments(userId))

    const removeHandler = (document) => {
        if (confirm('Are you sure you want to remove this?')) {
            const newOfflineDocuments = documents.filter(
                (d) => d.id !== document.id
            )
            localStorage.setItem(
                `${userId}-offlineDocuments`,
                JSON.stringify(newOfflineDocuments)
            )
            caches.open(DOCUMENT_CACHE_NAME).then((cache) => {
                cache.delete(`/documents/${document.id}/download`)
                cache.add('/documents/offline')
                if (document.thumbnail) {
                    cache.delete(document.thumbnail)
                }
            })
            setDocuments(newOfflineDocuments)
        }
    }

    return (
        <Fragment>
            {documents.length == 0 ? (
                <div className="col">
                    <h3>No documents under this category.</h3>
                </div>
            ) : (
                <Documents
                    documents={documents}
                    removeHandler={removeHandler}
                />
            )}
        </Fragment>
    )
}

const Documents = ({ documents, removeHandler }) => {
    return documents.map((document) => (
        <div key={document.id} className="d-flex card-gray w-100 mt-3 p-0">
            {document.thumbnail ? (
                <Fragment>
                    <div
                        className="d-none d-lg-flex justify-content-center align-items-center"
                        style={{
                            minHeight: '100px',
                            minWidth: '150px',
                            backgroundColor: 'var(--black)',
                        }}
                    >
                        <img
                            src={document.thumbnail}
                            style={{ maxHeight: '100px', maxWidth: '150px' }}
                        />
                    </div>
                    <div
                        className="d-flex d-lg-none justify-content-center align-items-center"
                        style={{
                            minHeight: '75px',
                            minWidth: '100px',
                            backgroundColor: 'var(--black)',
                        }}
                    >
                        <img
                            src={document.thumbnail}
                            style={{ maxHeight: '75px', maxWidth: '100px' }}
                        />
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <div
                        className="d-none d-lg-flex justify-content-center align-items-center"
                        style={{
                            backgroundColor: '#AEAEAE',
                            color: 'white',
                            minHeight: '100px',
                            padding: '0 50px',
                            fontSize: '50px',
                        }}
                    >
                        <span className="fe fe-image" />
                    </div>
                    <div
                        className="d-flex d-lg-none justify-content-center align-items-center"
                        style={{
                            backgroundColor: '#AEAEAE',
                            color: 'white',
                            minHeight: '75px',
                            padding: '0 32px',
                            fontSize: '35px',
                        }}
                    >
                        <span className="fe fe-image" />
                    </div>
                </Fragment>
            )}
            <div className="d-flex pr-3 pl-3 pr-lg-4 pl-lg-4 align-items-center w-100">
                <div className="mr-auto">
                    <h3 className="d-none d-lg-block m-0">{document.title}</h3>
                    <h5 className="d-block d-lg-none m-0">{document.title}</h5>
                </div>
                <div className="d-flex align-items-center">
                    <a
                        href={`/documents/${document.id}/download`}
                        className="d-flex mr-3 btn-link-black"
                    >
                        <span className="fe fe-download-cloud mr-lg-1"></span>
                        <span className="d-none d-lg-block">Download</span>
                    </a>
                    <button
                        type="button"
                        onClick={() => removeHandler(document)}
                        className="d-flex btn btn-link-black shadow-none"
                    >
                        <span className="fe fe-x mr-lg-1"></span>
                        <span className="d-none d-lg-block">Remove</span>
                    </button>
                </div>
            </div>
        </div>
    ))
}

export const getOfflineDocuments = (userId) => {
    if (userId === undefined) {
        return []
    }
    const offlineDocuments = JSON.parse(
        localStorage.getItem(`${userId}-offlineDocuments`)
    )
    return offlineDocuments === null ? [] : offlineDocuments
}

export default OfflineDocuments
