import React from 'react'

const PaginationContainer = ({
    offset,
    itemsPerPage,
    total,
    onPreviousPage,
    onNextPage,
}) => {
    return (
        <div className="d-flex justify-content-center align-items-center w-100">
            <button
                className={`btn btn-link-outline-grey ${
                    offset <= 0 ? 'disabled' : ''
                }`}
                style={{ padding: '4px 10px' }}
                onClick={onPreviousPage}
            >
                <span
                    className="fe fe-chevron-left font-weight-bold"
                    style={{ fontSize: '18px' }}
                ></span>
            </button>
            <span className="mt-1 mr-3 ml-3 font-size-lg">
                {offset * itemsPerPage + 1} -{' '}
                {(offset + 1) * itemsPerPage < total
                    ? (offset + 1) * itemsPerPage
                    : total}{' '}
                of {total}
            </span>
            <button
                className={`btn btn-link-outline-grey ${
                    (offset + 1) * itemsPerPage >= total ? 'disabled' : ''
                }`}
                style={{ padding: '4px 10px' }}
                onClick={onNextPage}
            >
                <span
                    className="fe fe-chevron-right font-weight-bold"
                    style={{ fontSize: '18px' }}
                ></span>
            </button>
        </div>
    )
}

export default PaginationContainer
