import React, { useState, Fragment, useEffect } from 'react'
import Async from 'react-select/async'
import { gql, useApolloClient } from '@apollo/client'

const SCHOOLS_QUERY = gql`
    query getSchools($name: String, $public: Boolean) {
        schools(name: $name, public: $public) {
            id
            displayName
        }
    }
`
const StudentProfileSelectSchool = ({ id, schoolName = '', type }) => {
    const [isPublic, setPublic] = useState(type || false)
    const [schoolId, setSchoolId] = useState(id || '')
    const [isSubmitted, setIsSubmitted] = useState(false)

    const client = useApolloClient()

    const fetchOptions = async (inputValue, cb) => {
        if (inputValue && inputValue.length > 2) {
            const { data } = await client.query({
                query: SCHOOLS_QUERY,
                variables: { name: inputValue, public: isPublic },
                fetchPolicy: 'network-only',
            })

            const options = data.schools.map((s) => ({
                label: s.displayName,
                value: s.id,
            }))

            await cb(options)
        } else {
            Promise.resolve(cb([]))
        }
    }

    const handleChange = (v) => {
        setSchoolId(v.value)
    }

    return(
        <div className="form-row">
            <div className="form-group col-12">
                <div className="form-check form-check-inline">
                    <label className="form-check-label">
                        <input
                            type="radio"
                            name="school_is_public"
                            value="true"
                            checked={isPublic}
                            className="form-check-input"
                            onChange={() => setPublic(true)}
                        />
                        Public
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label">
                        <input
                            type="radio"
                            name="school_is_public"
                            value="false"
                            checked={!isPublic}
                            className="form-check-input"
                            onChange={() => setPublic(false)}
                        />
                        Private
                    </label>
                </div>
            </div>
            <div className="form-group col-12">
                <input type="hidden" name="student[school_id]" value={schoolId} />
                <Async
                    onChange={handleChange}
                    loadOptions={fetchOptions}
                    defaultValue={
                        Boolean(schoolId)
                            ? { value: schoolId, label: schoolName }
                            : null
                    }
                    noOptionsMessage={({ inputValue }) =>
                        inputValue.length > 2
                            ? "Cannot find the school you're searching for..."
                            : 'Please enter at least 3 characters.'
                    }
                    placeholder="Search School Name or School ID..."
                />
            </div>
        </div>
    )
}

export default StudentProfileSelectSchool