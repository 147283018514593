import React, { useState, Fragment } from 'react'

const InputPassword = ({ name }) => {
    const [isHidden, setIsHidden] = useState(true)

    return (
        <div className="input-group input-group-merge">
            <input
                type={isHidden ? "password" : "text"}
                name={name}
                className="form-control form-control-prepended form-control-appended"
            />
            <div className="input-group-prepend">
                <div className="input-group-text">
                    <span className="fe fe-lock" />
                </div>
            </div>
            <div className="input-group-append">
                <button
                    type="button"
                    className="input-group-text btn shadow-none"
                    onClick={() => setIsHidden(!isHidden)}
                >
                    {isHidden ? (
                        <span className="fe fe-eye" />
                    ) : (
                        <span className="fe fe-eye-off" />
                    )}
                </button>
            </div>
        </div>
    )
}

export default InputPassword
