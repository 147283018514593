import React, { useState, Fragment, useEffect } from 'react'
import Async from 'react-select/async'
import { gql, useApolloClient } from '@apollo/client'

const SCHOOLS_QUERY = gql`
    query getSchools($name: String, $public: Boolean) {
        schools(name: $name, public: $public) {
            id
            displayName
        }
    }
`

const SelectSchool = ({ id, schoolName, type, name, email }) => {
    const [isPublic, setPublic] = useState(true)
    const [school, setSchool] = useState(
        id && schoolName ? { label: schoolName, value: id } : null
    )
    const [userType, setUserType] = useState(type || '') // "student", "teacher"
    const [userName, setUserName] = useState(name || '')
    const [userEmail, setUserEmail] = useState(email || '')

    const client = useApolloClient()

    const fetchOptions = async (inputValue, cb) => {
        if (inputValue && inputValue.length > 2) {
            const { data } = await client.query({
                query: SCHOOLS_QUERY,
                variables: { name: inputValue, public: isPublic },
                fetchPolicy: 'network-only',
            })

            const options = data.schools.map((s) => ({
                label: s.displayName,
                value: s.id,
            }))

            await cb(options)
        } else {
            Promise.resolve(cb([]))
        }
    }

    const handlePublicChange = (v) => {
        if (v !== isPublic) {
            setPublic(v)
            setSchool(null)
        }
    }

    const handleChange = (v) => {
        setSchool(v)
    }

    const isStudent = userType === 'student'

    return (
        <div>
            <div className="form-group">
                <div className="form-check form-check-inline">
                    <label className="form-check-label">
                        <input
                            type="radio"
                            name="school[is_public]"
                            value="true"
                            checked={isPublic}
                            className="form-check-input"
                            onChange={() => handlePublicChange(true)}
                        />
                        Public
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label">
                        <input
                            type="radio"
                            name="school[is_public]"
                            value="false"
                            checked={!isPublic}
                            className="form-check-input"
                            onChange={() => handlePublicChange(false)}
                        />
                        Private
                    </label>
                </div>
            </div>
            <div className="form-group">
                <Async
                    onChange={handleChange}
                    loadOptions={fetchOptions}
                    value={school}
                    noOptionsMessage={({ inputValue }) =>
                        inputValue.length > 2
                            ? "Cannot find the school you're searching for..."
                            : 'Please enter at least 3 characters.'
                    }
                    placeholder="Search School Name or School ID..."
                    className="mb-n3"
                />
                <input
                    name="school[id]"
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0, width: '100%' }}
                    value={school ? school.value : ''}
                    onChange={() => handleChange(school)}
                    required
                />
            </div>
            <div className="form-group">
                <div className="form-check form-check-inline">
                    <label className="form-check-label">
                        <input
                            type="radio"
                            name="guest[guest_type]"
                            value="student"
                            checked={userType === 'student'}
                            className="form-check-input"
                            onChange={() => setUserType('student')}
                            required
                        />
                        Student
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label">
                        <input
                            type="radio"
                            name="guest[guest_type]"
                            value="teacher"
                            checked={userType === 'teacher'}
                            className="form-check-input"
                            onChange={() => setUserType('teacher')}
                            required
                        />
                        Teacher
                    </label>
                </div>
            </div>
            <div className="form-group">
                <label className="w-100 d-block">
                    Name (required)
                    <input
                        type="text"
                        name="guest[name]"
                        value={userName}
                        required
                        onChange={(e) => setUserName(e.target.value)}
                        className="form-control"
                    />
                </label>
            </div>
            <div className="form-group">
                <label className="w-100 d-block">
                    <span>Email </span>{' '}
                    {isStudent ? (
                        <span>(optional)</span>
                    ) : (
                        <span>(required)</span>
                    )}
                    <input
                        type="email"
                        name="guest[email]"
                        value={userEmail}
                        required={!isStudent}
                        onChange={(e) => setUserEmail(e.target.value)}
                        className="form-control"
                    />
                </label>
            </div>
            <div
                style={{ display: 'flex', alignItems: 'center' }}
                className="mt-3"
            >
                <button
                    type="submit"
                    className="btn btn-outline-primary btn-block"
                >
                    Continue as Guest
                </button>
            </div>
        </div>
    )
}

export default SelectSchool
