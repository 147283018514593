import React, { useState, Fragment } from 'react'
import Axios from 'axios'
import Select from 'react-select'
import { gql, useApolloClient } from '@apollo/client'

const CATEGORIES_QUERY = gql`
    query getCategories($gradeId: String!) {
        categories(gradeId: $gradeId) {
            id
            name
        }
    }
`

const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: 'none',
        borderColor: state.isFocused ? 'var(--primary)' : 'var(--gray-light)',
        borderRadius: '0.375rem',
        '&:hover': {
            borderColor: state.isFocused
                ? 'var(--primary)'
                : 'var(--gray-light)',
        },
    }),
}

const transformToOptions = (r) => ({ value: r.id, label: r.name })

const SearchDocuments = ({
    grades,
    grade,
    categories = [],
    category,
    searchText,
}) => {
    const [searchGrade, setSearchGrade] = useState(
        grade ? { value: grade.id, label: grade.name } : null
    )
    const [searchCategory, setSearchCategory] = useState(
        category ? { value: category.id, label: category.name } : null
    )
    const [search, setSearch] = useState(searchText || '')
    const [categoryOptions, setCategoryOptions] = useState(
        categories.map(transformToOptions)
    )

    const gradeOptions = grades.map(transformToOptions)

    const client = useApolloClient()

    const handleSearchGradeChange = async (selectedGrade) => {
        if (selectedGrade) {
            const { data } = await client.query({
                query: CATEGORIES_QUERY,
                variables: { gradeId: selectedGrade.value },
                fetchPolicy: 'network-only',
            })

            setSearchGrade(selectedGrade)
            setSearchCategory(null)
            setCategoryOptions(data.categories.map(transformToOptions))
        } else {
            setSearchGrade(null)
            setSearchCategory(null)
            setCategoryOptions([])
        }
    }

    return (
        <div className="d-block d-lg-flex flex-wrap p-4">
            <div className="flex-1 pr-lg-4 mb-3 mb-lg-0">
                <input
                    type="hidden"
                    name="filter[grade]"
                    value={searchGrade !== null ? searchGrade.value : ''}
                />
                <Select
                    value={searchGrade}
                    onChange={handleSearchGradeChange}
                    options={gradeOptions}
                    placeholder="Grade"
                    isClearable={true}
                    styles={selectStyles}
                />
            </div>
            <div className="flex-2 pr-lg-4 mb-3 mb-lg-0">
                <input
                    type="hidden"
                    name="filter[category]"
                    value={searchCategory !== null ? searchCategory.value : ''}
                />
                <Select
                    value={searchCategory}
                    isDisabled={!searchGrade}
                    onChange={setSearchCategory}
                    options={categoryOptions}
                    placeholder={
                        !searchGrade
                            ? 'Select a grade to select a subject'
                            : 'Subject'
                    }
                    isClearable={true}
                    styles={selectStyles}
                />
            </div>
            <div className="flex-2 pr-lg-4 mb-3 mb-lg-0">
                <input
                    name="filter[search]"
                    placeholder="Title, authors, tags, type..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="form-control-gray"
                />
            </div>
            <button
                type="submit"
                className="btn btn-primary col-12 col-lg-auto pr-lg-5 pl-lg-5"
                style={{ height: '38px' }}
            >
                Search
            </button>
        </div>
    )
}

export default SearchDocuments
