import React, { Fragment, useState } from 'react'
import { getOfflineDocuments, DOCUMENT_CACHE_NAME } from './OfflineDocuments'

const DocumentAvailableOfflineToggle = ({
    documentId,
    title,
    thumbnail,
    noDropdown,
}) => {
    const userId = Cookies.get('user_id')
    const [available, setAvailable] = useState(
        getOfflineDocuments(userId).some((d) => d.id === documentId)
    )

    const toggleAvailabilityHandler = (event) => {
        const offlineDocuments = getOfflineDocuments(userId)
        if (available) {
            const newOfflineDocuments = offlineDocuments.filter(
                (d) => d.id !== documentId
            )
            localStorage.setItem(
                `${userId}-offlineDocuments`,
                JSON.stringify(newOfflineDocuments)
            )
            caches.open(DOCUMENT_CACHE_NAME).then((cache) => {
                cache.delete(`/documents/${documentId}/download`)
                if (thumbnail) {
                    cache.delete(thumbnail)
                }
            })
        } else {
            offlineDocuments.push({ id: documentId, title, thumbnail })
            localStorage.setItem(
                `${userId}-offlineDocuments`,
                JSON.stringify(offlineDocuments)
            )
            caches.open(DOCUMENT_CACHE_NAME).then((cache) => {
                cache.add(`/documents/${documentId}/download`)
                if (thumbnail) {
                    cache.add(thumbnail)
                }
            })
        }
        setAvailable(!available)
        event.preventDefault()
    }

    return (
        <Fragment>
            {noDropdown ? (
                <a
                    className="d-flex align-items-center justify-content-center py-2 px-3"
                    onClick={toggleAvailabilityHandler}
                    style={{
                        color: 'var(--black)',
                        backgroundColor: 'var(--gray-lighter)',
                        cursor: 'pointer',
                    }}
                >
                    {available ? (
                        <Fragment>
                            <AvailableOfflineIcon
                                stroke="var(--success)"
                                className="mr-2"
                            />
                            <span>Available Offline</span>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <AvailableOfflineIcon className="mr-2" />
                            <span>Make Available Offline</span>
                        </Fragment>
                    )}
                </a>
            ) : (
                <div className="d-flex align-items-center mt-n2">
                    {available && <AvailableOfflineIcon className="mr-1" />}
                    <div className="dropdown">
                        <a
                            href="#"
                            className="dropdown-ellipses dropdown-toggle"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i
                                className="fe fe-more-vertical"
                                style={{ color: 'var(--black)' }}
                            ></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <a
                                className="dropdown-item d-flex align-items-center"
                                onClick={toggleAvailabilityHandler}
                                style={{
                                    color: 'var(--black)',
                                    cursor: 'pointer',
                                }}
                            >
                                {available ? (
                                    <Fragment>
                                        <AvailableOfflineIcon
                                            stroke="var(--success)"
                                            className="mr-2"
                                        />
                                        <span
                                            style={{ color: 'var(--success)' }}
                                        >
                                            Available Offline
                                        </span>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <AvailableOfflineIcon className="mr-2" />
                                        <span>Make Available Offline</span>
                                    </Fragment>
                                )}
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

const AvailableOfflineIcon = ({ className, stroke }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        className={className}
    >
        <g
            id="Group_2534"
            data-name="Group 2534"
            transform="translate(-1118 -173)"
        >
            <g
                id="Ellipse_486"
                data-name="Ellipse 486"
                transform="translate(1118 173)"
                fill="none"
                stroke={stroke || 'var(--black)'}
                strokeWidth="1.25"
            >
                <circle cx="10.5" cy="10.5" r="10.5" stroke="none" />
                <circle cx="10.5" cy="10.5" r="9.875" fill="none" />
            </g>
            <path
                id="Path_4780"
                data-name="Path 4780"
                d="M17.509,4l-6.545,6.552L9,8.588"
                transform="translate(1115 174)"
                fill="none"
                stroke={stroke || 'var(--black)'}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <line
                id="Line_436"
                data-name="Line 436"
                x2="10"
                transform="translate(1124 188.083)"
                fill="none"
                stroke={stroke || 'var(--black)'}
                strokeWidth="1"
            />
        </g>
    </svg>
)

export default DocumentAvailableOfflineToggle
