import React, { useState, Fragment } from 'react'
import Axios from 'axios'
import moment from 'moment'
import Select from 'react-select'
import ApproverDocument from './ApproverDocument'
import ApproverDocumentModal from './ApproverDocumentModal'
import PaginationContainer from './containers/PaginationContainer'

const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: 'none',
        borderColor: 'var(--gray-light)',
        borderRadius: '0.375rem',
        '&:hover': {
            borderColor: 'var(--gray-light)',
        },
    }),
}

const smallSelectStyles = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: 'none',
        borderColor: 'var(--gray-light)',
        borderRadius: '0.375rem',
        '&:hover': {
            borderColor: 'var(--gray-light)',
        },
        minHeight: '28px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '28px',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '28px',
    }),
}

const statusOptions = [
    { value: 'Pending Approval', label: 'Pending Approval' },
    { value: 'Approved', label: 'Approved' },
    { value: 'For Revision', label: 'For Revision' },
    { value: 'Published', label: 'Published' },
    { value: 'Scheduled for Publish', label: 'Scheduled for Publish' },
]

const documentsPerPageOptions = [
    { value: 10, label: 'Showing 10 per page' },
    { value: 20, label: 'Showing 20 per page' },
    { value: 30, label: 'Showing 30 per page' },
]

const sortedByOptions = [
    { value: 'Ascending', label: 'Sort by Upload Date (Ascending)' },
    { value: 'Descending', label: 'Sort by Upload Date (Descending)' },
]

const transformToOptions = (r) => ({ value: r.id, label: r.name })

const ApproverDocuments = ({ grades = [], documents = [], isPublishOnly }) => {
    const [newDocuments, setNewDocuments] = useState(documents)
    const [filteredDocuments, setFilteredDocuments] = useState(documents)
    const [filterGrade, setFilterGrade] = useState(null)
    const [filterCategory, setFilterCategory] = useState(null)
    const [filterStatus, setFilterStatus] = useState(null)
    const [filterSearch, setFilterSearch] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const [selectedDocumentAction, setSelectedDocumentAction] = useState({
        selectedDocument: null,
        action: null,
    })

    const [categoryOptions, setCategoryOptions] = useState([])
    const [documentsPerPage, setDocumentsPerPage] = useState({
        value: 10,
        label: 'Showing 10 per page',
    })
    const [sortedBy, setSortedBy] = useState({
        value: 'Descending',
        label: 'Sort by Upload Date (Descending)',
    })
    const [offset, setOffset] = useState(0)

    const gradeOptions = grades.map(transformToOptions)

    const range = (offset + 1) * documentsPerPage.value

    const DocumentRows = filteredDocuments.map((d, i) => {
        if (i >= range - documentsPerPage.value && i < range) {
            return (
                <ApproverDocument
                    key={d.id}
                    document={d}
                    handleAction={(selectedDocument, action) => {
                        setSelectedDocumentAction({ selectedDocument, action })
                        setModalVisible(true)
                    }}
                    isPublishOnly={isPublishOnly}
                />
            )
        }
    })

    const handleNextPage = () => {
        if (
            offset + 1 <
            Math.ceil(filteredDocuments.length / documentsPerPage.value)
        ) {
            setOffset(offset + 1)
        }
    }

    const handlePreviousPage = () => {
        if (offset > 0) {
            setOffset(offset - 1)
        }
    }

    const handleSortByChange = (s) => {
        const sortBy = s.value
        const nDocuments =
            sortBy === 'Descending'
                ? [...newDocuments].sort(
                      (a, b) =>
                          moment(b.uploadedOn, 'M-D-YYYY; h:mm A') -
                          moment(a.uploadedOn, 'M-D-YYYY; h:mm A')
                  )
                : [...newDocuments].sort(
                      (a, b) =>
                          moment(a.uploadedOn, 'M-D-YYYY; h:mm A') -
                          moment(b.uploadedOn, 'M-D-YYYY; h:mm A')
                  )
        setSortedBy(s)
        setNewDocuments(nDocuments)
        filterDocuments({ documents: nDocuments })
    }

    const handleFilterGradeChange = (selectedGrade) => {
        setFilterGrade(selectedGrade)
        setCategoryOptions('loading')
        if (selectedGrade) {
            Axios.request({
                url: `/categories?grade_id=${selectedGrade.value}`,
            })
                .then((res) => res.data)
                .then((res) => {
                    setCategoryOptions(res.categories.map(transformToOptions))
                })
        } else {
            setCategoryOptions([])
        }
        setFilterCategory(null)
        filterDocuments({ grade: selectedGrade, category: null })
    }

    const handleFilterCategoryChange = (selectedCategory) => {
        setFilterCategory(selectedCategory)
        filterDocuments({ category: selectedCategory })
    }

    const handleFilterStatusChange = (selectedStatus) => {
        setFilterStatus(selectedStatus)
        filterDocuments({ status: selectedStatus })
    }

    const handleFilterSearchClear = () => {
        setFilterSearch('')
        filterDocuments({ search: '' })
    }

    const handleFilterSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            filterDocuments({})
        }
    }

    const filterDocuments = ({
        documents = newDocuments,
        search = filterSearch.toLowerCase().split(' '),
        grade = filterGrade,
        category = filterCategory,
        status = filterStatus,
    }) => {
        setOffset(0)
        setFilteredDocuments(
            documents.filter(
                (d) =>
                    (search.length === 0 ||
                        search.every(
                            (s) =>
                                d.title.toLowerCase().includes(s) ||
                                (d.resourceType &&
                                    d.resourceType.toLowerCase().includes(s)) ||
                                (d.authors &&
                                    d.authors.toLowerCase().includes(s)) ||
                                (d.tags && d.tags.toLowerCase().includes(s))
                        )) &&
                    (!grade || d.gradeId === grade.value) &&
                    (!category || d.categoryId === category.value) &&
                    (!status || d.status === status.value)
            )
        )
    }

    const handleModalActionSuccess = (document) => {
        const nDocuments = newDocuments.map((d) => {
            if (d.id === document.id) {
                return Object.assign({}, d, {
                    approvedOn: document.approvedOn,
                    approvedBy: document.approvedBy,
                    publishedOn: document.publishedOn,
                    publishedBy: document.publishedBy,
                    status: document.status,
                    scheduledPublishDate: document.scheduledPublishDate,
                })
            } else {
                return d
            }
        })
        setNewDocuments(nDocuments)
        filterDocuments({ documents: nDocuments })
    }

    return (
        <div>
            <ApproverDocumentModal
                modalVisible={modalVisible}
                onClose={() => setModalVisible(false)}
                onSuccess={handleModalActionSuccess}
                selectedDocument={selectedDocumentAction.selectedDocument}
                action={selectedDocumentAction.action}
            />
            <div className="d-flex mt-4 mb-4">
                <div className="input-group input-group-merge mr-2">
                    <input
                        placeholder="What document(s) are you looking for?"
                        value={filterSearch}
                        onChange={(e) => setFilterSearch(e.target.value)}
                        onKeyDown={handleFilterSearchKeyDown}
                        className="form-control form-control-prepended form-control-appended"
                        style={{
                            height: '38px',
                            color: 'var(--black)',
                            borderColor: 'var(--gray-light)',
                        }}
                    />
                    <div className="input-group-prepend">
                        <div
                            className="input-group-text"
                            style={{
                                height: '38px',
                                color: 'var(--black)',
                                borderColor: 'var(--gray-light)',
                            }}
                        >
                            <span className="fe fe-search" />
                        </div>
                    </div>
                    <div className="input-group-append">
                        {filterSearch.length > 0 ? (
                            <button
                                type="button"
                                className="input-group-text btn shadow-none"
                                onClick={handleFilterSearchClear}
                                style={{
                                    height: '38px',
                                    color: 'var(--black)',
                                    borderColor: 'var(--gray-light)',
                                }}
                            >
                                <span className="fe fe-x" />
                            </button>
                        ) : (
                            <div
                                className="input-group-text"
                                style={{
                                    height: '38px',
                                    color: 'var(--black)',
                                    borderColor: 'var(--gray-light)',
                                }}
                            />
                        )}
                    </div>
                </div>
                <button
                    type="button"
                    className="btn btn-primary outline-none"
                    style={{ height: '38px', width: '100px' }}
                    onClick={filterDocuments}
                >
                    Search
                </button>
            </div>
            <div className="d-flex mb-4">
                <div className="flex-3 mr-3">
                    <Select
                        value={filterGrade}
                        onChange={handleFilterGradeChange}
                        options={gradeOptions}
                        placeholder="Grade"
                        isClearable={true}
                        styles={selectStyles}
                    />
                </div>
                <div className="flex-3 mr-3">
                    <Select
                        value={filterCategory}
                        isDisabled={
                            categoryOptions === 'loading' || !filterGrade
                        }
                        isLoading={categoryOptions === 'loading'}
                        onChange={handleFilterCategoryChange}
                        options={categoryOptions}
                        placeholder={
                            !filterGrade
                                ? 'Select a grade to select a subject'
                                : 'Subject'
                        }
                        isClearable={true}
                        styles={selectStyles}
                    />
                </div>
                <div className="flex-2">
                    <Select
                        value={filterStatus}
                        onChange={handleFilterStatusChange}
                        options={statusOptions}
                        placeholder="Status"
                        isSearchable={false}
                        isClearable={true}
                        styles={selectStyles}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-end mb-3">
                <div className="mr-3" style={{ width: '280px' }}>
                    <Select
                        value={sortedBy}
                        onChange={handleSortByChange}
                        options={sortedByOptions}
                        styles={smallSelectStyles}
                    />
                </div>
                <div style={{ width: '200px' }}>
                    <Select
                        value={documentsPerPage}
                        onChange={setDocumentsPerPage}
                        options={documentsPerPageOptions}
                        styles={smallSelectStyles}
                    />
                </div>
            </div>
            {DocumentRows.length > 0 ? (
                <div
                    className="mb-4"
                    style={{
                        borderStyle: 'solid',
                        borderWidth: '1px 1px 0 1px',
                        borderColor: 'var(--gray-light)',
                    }}
                >
                    {DocumentRows}
                </div>
            ) : (
                <div className="d-flex justify-content-center mt-7">
                    <h3>No results found.</h3>
                </div>
            )}
            {filteredDocuments.length > documentsPerPage.value && (
                <PaginationContainer
                    offset={offset}
                    itemsPerPage={documentsPerPage.value}
                    total={filteredDocuments.length}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                />
            )}
        </div>
    )
}

export default ApproverDocuments
