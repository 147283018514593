import React, { useState, Fragment } from 'react'
import Axios from 'axios'
import Modal from 'react-modal'
import moment from 'moment'

const csrfMeta = [...document.getElementsByName('csrf-token')].pop()

const ApproverDocumentModal = ({
    modalVisible = false,
    onClose,
    onSuccess,
    selectedDocument,
    action,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [isConfirm, setIsConfirm] = useState(false)
    const [scheduledPublish, setScheduledPublish] = useState('')
    const isSchedule =
        action === 'Schedule Publish' ||
        action === 'Approve and Schedule Publish'

    const handleAfterOpen = () => {
        if (
            selectedDocument &&
            selectedDocument.status === 'Scheduled for Publish'
        ) {
            setScheduledPublish(selectedDocument.scheduledPublishDate)
        }
    }

    const handleProceedAction = () => {
        setIsLoading(true)
        setMessage(`Processing ${action.toLowerCase()} please wait.`)
        const method = action === 'Remove Schedule Publish' ? 'delete' : 'post'
        const data = isSchedule
            ? {
                  authenticity_token: csrfMeta.content,
                  scheduled_publish_date: scheduledPublish,
              }
            : {
                  authenticity_token: csrfMeta.content,
              }
        Axios.request({
            url: `/approver/documents/${
                selectedDocument.id
            }/${action.toLowerCase().split(' ').join('_')}`,
            headers: {
                Accept: 'application/json',
            },
            method: method,
            data: data,
        })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    onSuccess(res.document)
                }
                setMessage(res.message)
                setIsConfirm(true)
                setScheduledPublish('')
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setMessage('Something went wrong please try again later.')
                setIsConfirm(true)
                setScheduledPublish('')
                setIsLoading(false)
            })
    }

    const handleClose = () => {
        setMessage('')
        setIsConfirm(false)
        setScheduledPublish('')
        onClose()
    }

    return (
        <Modal
            appElement={document.getElementById('root')}
            isOpen={modalVisible}
            onAfterOpen={handleAfterOpen}
            onRequestClose={isLoading ? null : handleClose}
            className="modal-dialog"
            style={{
                overlay: {
                    backgroundColor: '#12263f80',
                },
            }}
        >
            <div className="modal-content p-4">
                <div
                    className="modal-header pr-0 pl-0"
                    style={{ borderBottomColor: 'var(--gray-light)' }}
                >
                    <h2 className="modal-title">{action}</h2>
                </div>
                <div className="modal-body pr-0 pl-0">
                    {isSchedule && !isConfirm ? (
                        <div className="form-group">
                            <label>Scheduled publish date</label>
                            <input
                                type="date"
                                className="form-control"
                                min={moment().add(1, 'days').format("YYYY-MM-DD")}
                                value={scheduledPublish}
                                onChange={(e) =>
                                    setScheduledPublish(e.target.value)
                                }
                            />
                        </div>
                    ) : (
                        <p>
                            {message
                                ? message
                                : action === 'For Revision'
                                ? `Are you sure you want to set ${selectedDocument.title} to "For Revision"?`
                                : action &&
                                  `Are you sure you want to ${action.toLowerCase()} ${
                                      selectedDocument.title
                                  }?`}
                        </p>
                    )}
                </div>
                <div
                    className="modal-footer pt-0 pr-0 pb-3 pl-0"
                    style={{ border: 'none' }}
                >
                    {isLoading ? (
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : !isConfirm ? (
                        <Fragment>
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={handleProceedAction}
                            >
                                Proceed
                            </button>
                        </Fragment>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleClose}
                        >
                            OK
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default ApproverDocumentModal
