import React, { useState } from 'react'
import InputPassword from './InputPassword'

const SignInFields = ({ resourceName, isRememberable = false, email = '' }) => {
    const [isShowFields, setShowFields] = useState(!!email)

    return isShowFields ? (
        <div className="mb-3">
            <div className="mb-2 fade-in">
                <label className="mb-1" htmlFor="email">
                    Email address
                </label>
                <div className="input-group input-group-merge">
                    <input
                        autoFocus="autofocus"
                        autoComplete="email"
                        className="form-control form-control-prepended"
                        type="email"
                        name={`${resourceName}[email]`}
                        id="email"
                        defaultValue={email}
                        required
                    />
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <span className="fe fe-mail"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 fade-in">
                <label className="mb-1">Password</label>
                <InputPassword name={`${resourceName}[password]`} />
            </div>
            <div className="d-flex">
                {isRememberable && (
                    <div className="d-flex align-items-center fade-in">
                        <input
                            name={`[${resourceName}][remember_me]`}
                            type="hidden"
                            value="0"
                        />
                        <input
                            type="checkbox"
                            value="1"
                            name={`[${resourceName}][remember_me]`}
                            id="remember_me"
                        />
                        <label className="mb-0 ml-1" htmlFor="remember">
                            Keep me logged in
                        </label>
                    </div>
                )}
                <a href={`/${resourceName}s/password/new`} className="ml-auto fade-in">
                    Forgot password?
                </a>
            </div>
            <div className="mt-4">
                <input
                    type="submit"
                    name="commit"
                    value="Login to Commons Account"
                    className="btn btn-warning btn-block"
                    data-disable-with="Login to Commons Account"
                />
            </div>
        </div>
    ) : (
        <button
            className="d-flex btn btn-warning justify-content-center align-items-center w-100"
            onClick={() => setShowFields(true)}
        >
            <span className="mr-2">Login to Commons Account</span>
            <span className="fe fe-chevron-right"></span>
        </button>
    )
}

export default SignInFields
