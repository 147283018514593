import React, { Fragment } from 'react'
import defaultThumbnail from '../../assets/images/deped-icon-blue.svg'

const ApproverDocument = ({ document, handleAction, isPublishOnly }) => (
    <div
        className="position-relative p-4"
        style={{
            borderStyle: 'solid',
            borderWidth: '0 0 1px 0',
            borderColor: 'var(--gray-light)',
        }}
    >
        <a
            href={`/documents/${document.id}`}
            className="d-block position-absolute block-link"
            style={{ top: 0, right: 0, bottom: 0, left: 0 }}
        ></a>
        <div className="d-flex mb-3">
            <div
                className="d-flex align-items-center"
                style={{ flexBasis: '100%', minWidth: 0 }}
            >
                {document.thumbnail ? (
                    <div
                        className="d-flex justify-content-center align-items-center mr-4"
                        style={{
                            minHeight: '120px',
                            minWidth: '180px',
                            backgroundColor: 'var(--black)',
                        }}
                    >
                        <img
                            src={`/documents/${document.id}/thumbnail`}
                            style={{
                                maxHeight: '120px',
                                maxWidth: '180px',
                            }}
                        />
                    </div>
                ) : (
                    <div
                        className="d-flex justify-content-center align-items-center mr-4"
                        style={{
                            minHeight: '120px',
                            minWidth: '180px',
                            backgroundColor: '#F1F5FF',
                        }}
                    >
                        <img
                            src={defaultThumbnail}
                            style={{
                                maxHeight: '60px',
                                maxWidth: '90px',
                            }}
                        />
                    </div>
                )}
                <div style={{ minWidth: 0 }}>
                    <h5 className="text-truncate mb-1">{document.title}</h5>
                    {document.authors && (
                        <div className="d-flex align-items-center">
                            <span className="fe fe-users mr-2" />
                            {document.authors}
                        </div>
                    )}
                    <span
                        className={`badge ${DocumentStatusClassHelper(
                            document.status
                        )} mr-2`}
                    >
                        {document.status}
                    </span>
                    {document.resourceType && (
                        <span
                            className={`badge badge-document-resource-type badge-${stringParameterize(
                                document.resourceType
                            )}`}
                        >
                            {document.resourceType}
                        </span>
                    )}
                    <div className="d-flex align-items-center">
                        <span className="fe fe-layers mr-2" />
                        {document.gradeName} &bull; {document.categoryName}
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="fe fe-eye mr-2" />
                        {document.viewCount} views
                    </div>
                    {document.tags && (
                        <div className="d-flex align-items-center">
                            <span className="fe fe-tag mr-2" />
                            {document.tags}
                        </div>
                    )}
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
                {(document.status === 'Pending Approval' ||
                    document.status === 'For Revision') &&
                    !isPublishOnly && (
                        <Fragment>
                            <button
                                type="button"
                                className="btn btn-danger d-flex align-items-center position-relative outline-none mr-3"
                                style={{ whiteSpace: 'nowrap' }}
                                onClick={() =>
                                    handleAction(document, 'For Revision')
                                }
                            >
                                <span className="fe fe-x mr-1"></span>For
                                Revision
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary d-flex align-items-center position-relative outline-none mr-3"
                                onClick={() =>
                                    handleAction(document, 'Approve')
                                }
                            >
                                <span className="fe fe-check mr-1"></span>
                                Approve
                            </button>
                            <div className="btn-group" role="group">
                                <button
                                    id="approveAndPublishButtonGroup"
                                    type="button"
                                    className="btn btn-success d-flex align-items-center dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <span className="fe fe-check mr-1"></span>
                                    Approve and publish
                                    <span className="vertical-divider ml-3 mr-2"></span>
                                </button>
                                <div
                                    className="dropdown-menu ml-n5"
                                    aria-labelledby="approveAndPublishButtonGroup"
                                >
                                    <a
                                        className="dropdown-item"
                                        href="#/"
                                        onClick={() =>
                                            handleAction(
                                                document,
                                                'Approve and Publish'
                                            )
                                        }
                                    >
                                        Approve and Publish Now
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="#/"
                                        onClick={() =>
                                            handleAction(
                                                document,
                                                'Approve and Schedule Publish'
                                            )
                                        }
                                    >
                                        Approve and Schedule Publish
                                    </a>
                                </div>
                            </div>
                        </Fragment>
                    )}
                {document.status === 'Approved' && (
                    <div className="btn-group" role="group">
                        <button
                            id="publishButtonGroup"
                            type="button"
                            className="btn btn-success d-flex align-items-center dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span className="fe fe-external-link mr-1"></span>
                            Publish
                            <span className="vertical-divider ml-3 mr-2"></span>
                        </button>
                        <div
                            className="dropdown-menu ml-n5"
                            aria-labelledby="publishButtonGroup"
                        >
                            <a
                                className="dropdown-item"
                                href="#/"
                                onClick={() =>
                                    handleAction(document, 'Publish')
                                }
                            >
                                Publish Now
                            </a>
                            <a
                                className="dropdown-item"
                                href="#/"
                                onClick={() =>
                                    handleAction(document, 'Schedule Publish')
                                }
                            >
                                Schedule Publish
                            </a>
                        </div>
                    </div>
                )}
                {document.status === 'Published' && (
                    <button
                        type="button"
                        className="btn btn-grey d-flex align-items-center position-relative outline-none"
                        onClick={() => handleAction(document, 'Unpublish')}
                    >
                        <span className="fe fe-x-circle mr-1"></span>
                        Unpublish
                    </button>
                )}
                {document.status === 'Scheduled for Publish' && (
                    <div className="btn-group" role="group">
                        <button
                            id="scheduleButtonGroup"
                            type="button"
                            className="btn btn-primary d-flex align-items-center dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span className="fe fe-edit mr-1"></span>Edit
                            Schedule
                            <span className="vertical-divider ml-3 mr-2"></span>
                        </button>
                        <div
                            className="dropdown-menu ml-n5"
                            aria-labelledby="scheduleButtonGroup"
                        >
                            <a
                                className="dropdown-item"
                                href="#/"
                                onClick={() =>
                                    handleAction(document, 'Schedule Publish')
                                }
                            >
                                Reschedule
                            </a>
                            <a
                                className="dropdown-item"
                                href="#/"
                                onClick={() =>
                                    handleAction(
                                        document,
                                        'Remove Schedule Publish'
                                    )
                                }
                            >
                                Remove Schedule
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
        <div className="d-flex flex-wrap">
            <div className="d-flex align-items-center mr-4">
                <span className="fe fe-user mr-2" />
                Uploaded on: {document.uploadedOn}
                {document.uploadedBy && ` by ${document.uploadedBy}`}
            </div>
            {document.approvedOn && (
                <div className="d-flex align-items-center mr-4">
                    <span className="fe fe-check-circle mr-2" />
                    Approved on: {document.approvedOn} by {document.approvedBy}
                </div>
            )}
            {document.publishedOn && (
                <div className="d-flex align-items-center">
                    <span className="fe fe-external-link mr-2" />
                    Published on: {document.publishedOn} by{' '}
                    {document.publishedBy}
                </div>
            )}
        </div>
    </div>
)

const DocumentStatusClassHelper = (status) => {
    switch (status) {
        case 'Pending Approval':
            return 'badge-soft-warning'
        case 'Approved':
            return 'badge-soft-info'
        case 'For Revision':
            return 'badge-soft-danger'
        case 'Published':
            return 'badge-soft-success'
        default:
            return 'badge-soft-primary'
    }
}

export default ApproverDocument
